import { useQuery } from '@apollo/client';
import { Button, Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FilePDF, Icon } from '../../../assets/svg';
import {
  AI_TOOLTIP_MESSAGE,
  defaultDateFormat,
  EMPTY_STATE,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { Attachments, Response } from '../../../types/common.type';
import { E_PROCEEDINGS_DETAILS } from './graphql/Queries';
const { Text } = Typography;

const AttachmentColumn = ({ attachment }: { attachment: Attachments[] }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      {/* kept this code for feature use */}
      {/*  <span className="mr-8">
        <FilePDF />
      </span> */}
      {/* <Link target="_blank" to={attachment[0]?.url ?? ''} download>
        {attachment[0]?.name}
      </Link> */}
      {attachment?.length > 0 && (
        <Button
          type="link"
          className="underline-btn"
          onClick={() => setOpenModal(true)}
        >
          View All
        </Button>
      )}
      <div className="attachments">
        <CommonModal
          className="h-512"
          title="Attachments"
          open={openModal}
          footer={false}
          closable={true}
          onCancel={() => setOpenModal(false)}
          wrapClassName="attachment-modal"
        >
          <div className="d-flex gap-16 flex-vertical">
            {attachment?.map((file: Attachments, index: number) => (
              <div className="d-flex align-center" key={index}>
                <span className="mr-8 d-flex">
                  <FilePDF />
                </span>
                <Link
                  className="color-blue max-width-430"
                  to={file?.url || ''}
                  download
                  target="_blank"
                >
                  {file?.name}
                </Link>
              </div>
            ))}
          </div>
        </CommonModal>
      </div>
    </div>
  );
};

const columns: ColumnType<Response>[] = [
  {
    title: 'Response/Remark',
    dataIndex: 'remarks',
    key: 'remarks',
    fixed: 'left',
    width: '40%',
    render: (response) => (
      <Tooltip placement="right" title={response}>
        {response?.slice(0, 2000)}
      </Tooltip>
    ),
  },
  {
    title: 'Submit Date',
    dataIndex: 'submittedOn',
    key: 'submittedOn',
    render: (submitDate) =>
      submitDate ? dayjs(submitDate).format(defaultDateFormat) : '-',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (text) => text ?? '-',
  },
  {
    title: 'Filed By',
    dataIndex: 'filedBy',
    key: 'filedBy',
    render: (text) => text ?? '-',
  },
  {
    title: 'Attachment',
    dataIndex: 'responses',
    key: 'responses',
    render: (text, record) =>
      record?.attachments?.length ?? 0 > 0 ? (
        <AttachmentColumn
          key={text}
          attachment={record.attachments as Attachments[]}
        />
      ) : (
        '-'
      ),
  },
  {
    title: 'Action',
    width: 150,
    fixed: 'right',
    align: 'center',
    render: () => {
      const askAI = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;
      return (
        <Space>
          <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
            <Button
              type="default"
              icon={<Icon />}
              className="d-flex align-center ask-ai-btn pointer"
              onClick={() => window.open(askAI)}
            >
              Ask AI
            </Button>
          </Tooltip>
        </Space>
      );
    },
  },
];

const EProcessingResponse = () => {
  const { params, navigate } = useRouter();
  const [searchParams] = useSearchParams();
  const { resId } = params;
  const client = searchParams.get('client');
  const clientLabel = searchParams.get('clientLabel');

  const { data: { eProceeding } = {}, loading } = useQuery(
    E_PROCEEDINGS_DETAILS,
    {
      skip: !resId,
      variables: {
        where: {
          id: resId,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  return (
    <div className="container">
      <div className="mt-16 mb-16 d-flex justify-between gap-32">
        <MyBreadcrumb
          username={eProceeding?.assessee?.username ?? (clientLabel || '')}
          id={eProceeding?.assessee?._id ?? (client || '')}
          assesseeId={eProceeding?.assessee?._id ?? (client || '' || '')}
        />
        <Text className="title">
          {eProceeding?.assessee?.username} | {eProceeding?.assessee?.name}
        </Text>
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">View Responses</Text>
      </div>
      <Card className="mt-16 mb-16 p-8" loading={loading}>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mb-16">
          <Col xs={12} lg={6} xl={6} className="d-grid">
            <Text className="title">Proceeding Name</Text>
            <Text className="value">{eProceeding?.name}</Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid">
            <Text className="title">PAN</Text>
            <Text className="value">{eProceeding?.assessee?.username}</Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid ">
            <Text className="title">Assessee Name</Text>
            <Text className="value">{eProceeding?.assessee?.name}</Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid ">
            <Text className="title">Financial Year</Text>
            <Text className="value">{eProceeding?.financialYear}</Text>
          </Col>
        </Row>
        <Row
          gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
          className="mb-16 mt-16"
        >
          <Col xs={12} lg={6} xl={6} className="d-grid">
            <Text className="title">Assessment Year</Text>
            <Text className="value">
              {eProceeding?.assessmentYear || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid">
            <Text className="title">Document reference ID</Text>
            <Text className="value">
              {eProceeding?.documentRefId || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid ">
            <Text className="title">Notice Section</Text>
            <Text className="value">
              {eProceeding?.noticeUs || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-grid ">
            <Text className="title">Served on</Text>
            <Text className="value">
              {eProceeding?.servedOn || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
        </Row>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
          <Col xs={24} sm={12} lg={12} className="d-grid">
            <Text className="title">Description</Text>
            <Text className="value">
              {eProceeding?.description || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={24} sm={12} lg={12} className="d-grid">
            <Text className="title">Email</Text>
            <Text className="value">
              {[eProceeding?.letterPdf?.to, eProceeding?.letterPdf?.cc]
                .filter(Boolean)
                .join(', ') || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
        </Row>
      </Card>
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={eProceeding?.responses as Response[]}
        pagination={false}
        scroll={{ x: 'max-content' }}
        locale={EMPTY_STATE}
        rowKey="_id"
      />
      <Button
        type="default"
        className="float-right mt-16"
        onClick={() => navigate(-1)}
      >
        Back To List
      </Button>
    </div>
  );
};

export default EProcessingResponse;
