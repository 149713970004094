import { gql } from '../../../../__generated__';

const NOTICES_ORDERS_LIST =
  gql(`query NoticesAndOrders($filter: NoticesAndOrdersFilter, $sort: [NoticesAndOrdersSort]) {
  noticesAndOrders(filter: $filter, sort: $sort) {
    count
    data {
      _id
      company {
        _id
        gstNumber
        tradeName
        username
      }
      attachments {
        name
        url
      }
      demandAmount
      dueDate
      issuedBy
      refId
      type
      description
      issuanceDate
      status
      remarks
    }
  }
}
`);

const ADDITIONAL_NOTICES_ORDERS_LIST =
  gql(`query AdditionalNotices($filter: AdditionalNoticesFilter, $sort: [AdditionalNoticesSort]) {
    additionalNotices(filter: $filter, sort: $sort) {
      count
      data {
        _id
        company {
          _id
          gstNumber
          tradeName
          username
        }
        section
        sectionNo
        refId
        arn
        type
        issuanceDate
        replyDueDate
        replies {
          replyDate
          repliedAgainstFiled
        }
        description
        status
        remarks
      }
    }
  }
`);

const NOTICE_DETAILS =
  gql(`query AdditionalNotice($where: AdditionalNoticeUniqueInput!) {
    additionalNotice(where: $where) {
      arn
      arnDate
      gstIn
      status
      INTIMATION {
        _id
        type
        refId
        issuanceDate
        replyDueDate
        sectionNo
        attachments {
          name
          url
        }
      }
      NOTICE {
        type
        refId
        issuanceDate
        replyDueDate
        sectionNo
        personalHearing
        financialYear
        attachments {
          url
          name
        }
      }
      REPLY {
        replyDate
        type
        caseId
        repliedAgainstFiled
        personalHearing
        refId
      }
      ORDER {
        _id
        type
        section
        refId
        issuanceDate
        replyDueDate
      }
    }
  }
`);

const SECTION_LIST = gql(`query sectionsQuery {
    additionalNoticeSections
  }
`);

const NOTICE_COUNT_QUERY =
  gql(`query NoticeCounts($filter: NoticesAndOrdersFilter) {
  noticeCounts(filter: $filter) {
    noticeOrderCounts
    totalCounts
    additionalNoticeCounts
  }
}
`);

export {
  ADDITIONAL_NOTICES_ORDERS_LIST,
  NOTICE_DETAILS,
  NOTICES_ORDERS_LIST,
  SECTION_LIST,
  NOTICE_COUNT_QUERY,
};
