import { useQuery } from '@apollo/client';
import { Button, Card, Col, Row, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Checks, DownloadSimple, FilePDF } from '../../../assets/svg';
import {
  defaultDateFormat,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { ITF } from './graphql/Queries';
const { Text, Title } = Typography;

const OtherFiledResponse = () => {
  const { navigate, params } = useRouter();
  const [searchParams] = useSearchParams();
  const { resId } = params;
  const [openModal, setOpenModal] = useState(false);
  const client = searchParams.get('client');

  const { data: { itf } = {}, loading: loadResponse } = useQuery(ITF, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: { where: { id: resId } },
  });

  const cancel = () => {
    setOpenModal(false);
  };

  const handleAttachments = (e: React.MouseEvent) => {
    if (itf?.attachments && itf.attachments.length > 1) {
      e.preventDefault();
      setOpenModal(true);
    }
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16 d-flex justify-between gap-32">
        <MyBreadcrumb
          username={itf?.assessee?.username ?? (client || '')}
          id={itf?.assessee?._id ?? (client || '')}
        />
        <Text className="title">
          {itf?.assessee?.username} | {itf?.assessee?.name}
        </Text>
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">Other Filed Forms</Text>
      </div>
      <LoaderComponent spinning={loadResponse}>
        <Card className="mt-16">
          <Title level={5} className="mb-24 mt-0">
            {itf?.name || NA_PLACEHOLDER_TEXT}
          </Title>
          <div className="mb-40 mt-40">
            <Row
              gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
              className="mb-16"
            >
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Form number</Text>
                <Text className="value">
                  {itf?.formNo || NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Assessment Year</Text>
                <Text className="value">
                  {itf?.assessmentYear || NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Filing date</Text>
                <Text className="value">
                  {itf?.filingDate
                    ? dayjs(itf?.filingDate).format(defaultDateFormat)
                    : NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Filed type</Text>
                <Text className="value">
                  {itf?.filingType || NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
            </Row>
            <Row
              gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
              className="mb-16"
            >
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Status</Text>
                <Text>
                  <Tag color="blue" title="verified">
                    {itf?.status || NA_PLACEHOLDER_TEXT}
                  </Tag>
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Acknowledgement no</Text>
                <Text className="value">
                  {itf?.acknowledgmentNo || NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Filed by</Text>
                <Text className="value">
                  {itf?.filedBy || NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
              <Col xs={12} lg={6} xl={6} className="d-grid">
                <Text className="title">Form Submitted</Text>
                <Text className="value">
                  {itf?.formSubmittedDate
                    ? dayjs(itf?.formSubmittedDate).format(defaultDateFormat)
                    : NA_PLACEHOLDER_TEXT}
                </Text>
              </Col>
            </Row>
          </div>
          <Row gutter={[24, 24]} className="mb-8" key="1">
            <Col xs={24} lg={24} xl={24}>
              <Card>
                <div className="submission-card d-flex justify-between gap-16">
                  <div className="d-flex align-center">
                    <span className="check-icon">
                      <Checks />
                    </span>
                    <div className="d-grid detail">
                      <Text>Activity / Status</Text>
                      <Text className="title">
                        To be displayed for AY {itf?.assessmentYear} onwards
                      </Text>
                    </div>
                  </div>
                  <div className="d-grid files-detail">
                    <Link
                      className="color-blue multi-pdf"
                      to={itf?.forms?.[0]?.url ?? ''}
                      download
                      target="_blank"
                    >
                      <Button
                        title={
                          !itf?.forms?.length
                            ? 'No forms available to download'
                            : ''
                        }
                        type="link"
                        className="d-flex align-center "
                        icon={<DownloadSimple />}
                        disabled={!itf?.forms?.length}
                      >
                        <span className="fs-12">Download Form</span>
                      </Button>
                    </Link>

                    <Link
                      className="color-blue multi-pdf"
                      to={itf?.receipts?.[0]?.url ?? ''}
                      download
                      target="_blank"
                    >
                      <Button
                        title={
                          !itf?.receipts?.length
                            ? 'No receipts available to download'
                            : ''
                        }
                        type="link"
                        className="d-flex align-center"
                        icon={<DownloadSimple />}
                        disabled={!itf?.receipts?.length}
                      >
                        Download Receipt
                      </Button>
                    </Link>
                    <Link
                      className="color-blue multi-pdf"
                      to={itf?.attachments?.[0]?.url ?? ''}
                      download
                      target="_blank"
                      onClick={handleAttachments}
                    >
                      <Button
                        title={
                          !itf?.attachments?.length
                            ? 'No attachment available to download'
                            : ''
                        }
                        type="link"
                        className="d-flex align-center"
                        icon={<DownloadSimple />}
                        disabled={!itf?.attachments?.length}
                      >
                        Download Attachment
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <Button
            type="default"
            className="float-right"
            onClick={() => navigate(-1)}
          >
            Back To List
          </Button>
        </Card>
      </LoaderComponent>
      {openModal && (
        <div className="attachments">
          <CommonModal
            className="h-512"
            title="Attachments"
            open={openModal}
            footer={false}
            closable={true}
            onCancel={cancel}
            wrapClassName="attachment-modal"
          >
            <div className="d-flex gap-16 flex-vertical">
              {itf?.attachments?.map((file, index: number) => (
                <div className="d-flex align-center" key={index}>
                  <span className="mr-8 d-flex">
                    <FilePDF />
                  </span>
                  <Link
                    className="color-blue max-width-430"
                    to={file?.url ?? ''}
                    download={file?.url}
                    target="_blank"
                  >
                    {file?.url?.split('/').slice(-2).join('/').split('?')[0] ??
                      ''}
                  </Link>
                </div>
              ))}
            </div>
          </CommonModal>
        </div>
      )}
    </div>
  );
};

export default OtherFiledResponse;
