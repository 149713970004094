import { gql } from '../../../../__generated__';

export const COMPANY_LIST =
  gql(`query Company_list($sort: [CompaniesSort]!, $filter: CompaniesFilter) {
    companies(sort: $sort, filter: $filter) {
      data {
        _id
        tradeName
        gstNumber
        username
      }
      count
    }
  }`);

export const COMPANIES =
  gql(`query Companies($sort: [CompaniesSort]!, $filter: CompaniesFilter) {
  companies(sort: $sort, filter: $filter) {
    data {
      _id
      businessName
      tradeName
      gstNumber
      username
      password
      centerJurisdiction
      stateJurisdiction
      registrationDate
      constitutionOfBusiness
      taxPayerType
      gstStatus
      placeOfBusiness
      proprietor
      authorizedSignature
      natureOfBusinessActivity
      isActive
      createdBy
      createdAt
      updatedAt
      complianceRating
      fieldVisitConducted
      hsn
      periodicityReturn
      activityLogs {
        _id
        portal
        pan
        gst
        status
        message
        createdAt
        updatedAt
        assesseeId
        companyId
      }
    }
    count
  }
}`);

export const COMPANY = gql(`query Company($where: CompanyUniqueInput!) {
  company(where: $where) {
    _id
    businessName
    tradeName
    gstNumber
    username
    password
    centerJurisdiction
    stateJurisdiction
    registrationDate
    constitutionOfBusiness
    taxPayerType
    gstStatus
    placeOfBusiness
    proprietor
    authorizedSignature
    natureOfBusinessActivity
    isActive
    createdBy
    createdAt
    updatedAt
    fieldVisitConducted
    complianceRating
    hsn
    periodicityReturn
  }
}
`);

export const COMPANY_STATES =
  gql(`query CompanyStats($where: CompanyUniqueInput) {
  companyStats(where: $where) {
    allNotices
    dueToday
    last24Hours
    openNotices
    oneWeekDue
    overDue
    loginFailed
    totalCompany
  }
}`);

export const GET_TRACK_RETURN_STATUS =
  gql(`query TrackReturnStatusLists($filter: TrackReturnStatusFilter!) {
    trackReturnStatusList(filter: $filter) {
      GSTR1 {
        _id
        companyId
        gstType
        taxPeriod
        financialYear
        status
        dateOfFiling
        createdAt
        updatedAt
      }
      GSTR3B {
        _id
        companyId
        gstType
        taxPeriod
        financialYear
        status
        dateOfFiling
        createdAt
        updatedAt
      }
      GSTR9 {
        _id
        companyId
        gstType
        taxPeriod
        financialYear
        status
        dateOfFiling
        createdAt
        updatedAt
      }
      GSTR9C {
        _id
        companyId
        gstType
        taxPeriod
        financialYear
        status
        dateOfFiling
        createdAt
        updatedAt
      }
    }
  }`);

export const NOTICES_AND_ORDERS =
  gql(`query NoticesAndOrdersData($filter: NoticesAndOrdersFilter, $sort: [NoticesAndOrdersSort]) {
    noticesAndOrders(filter: $filter, sort: $sort) {
      data {
        _id
        caseTaskId
        companyId
        refId
        description
        dueDate
        issuanceDate
        company {
          tradeName
          username
          gstNumber
        }
        type
      }
      count
    }
  }
  `);

export const SYNC_LOG =
  gql(`query SyncLogs($filter: SyncLogsFilter!, $sort: [SyncLogsSort]) {
  syncLogs(filter: $filter, sort: $sort) {
    count
    data {
      _id
      portal
      pan
      status
      message
      createdAt
      updatedAt
      company {
        _id
        businessName
        tradeName
        gstNumber
        username
        centerJurisdiction
        stateJurisdiction
        registrationDate
        constitutionOfBusiness
        taxPayerType
        gstStatus
        placeOfBusiness
        proprietor
        authorizedSignature
        natureOfBusinessActivity
        isActive
        createdBy
        createdAt
        updatedAt
      }
    }
  }
}`);
