import { gql } from '../../../../__generated__';

export const DISCONNECT_CALENDER = gql(`
  mutation DisconnectCalender {
  disconnectCalender {
    message
  }
}`);

export const UPDATE_ADDITIONAL_NOTICES_STATUS = gql(`
  mutation UpdateAdditionalNoticeStatus($where: AdditionalNoticeUniqueIdsInput!, $data: UpdateAdditionalNoticeStatusData!) {
   updateAdditionalNoticeStatus(where: $where, data: $data) {
     message
   }
 }`);

export const UPDATE_NOTICE_ORDER_STATUS = gql(`
  mutation UpdateNoticeAndOrderStatus($where: NoticeAndOrderUniqueIdsInput!, $data: UpdateNoticeAndOrderStatusData!) {
   updateNoticeAndOrderStatus(where: $where, data: $data) {
     message
   }
 }`);
