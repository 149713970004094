import { Button, Carousel, Col, Row } from 'antd';
import FeatureBanner from '../../assets/images/notice-banner.jpg';
import { ROUTES } from '../../common/constants';
import { Cookie } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import PublicCommonFooter from './components/PublicCommonFooter';
import PublicCommonHeader from './components/PublicCommonHeader';
import { RoundArrow } from '../../assets/svg';

function Home() {
  const { navigate } = useRouter();

  const handleNavigate = (route: string) => {
    const idToken = Cookie.get(Cookie.keys.TOKEN);
    let redirectUrl = route;
    if (!idToken) {
      const redirectTo = route?.includes(ROUTES?.INDIRECT_TAX)
        ? ROUTES?.INDIRECT_TAX
        : `/${ROUTES?.DIRECT_TAX}`;
      redirectUrl = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.LOGIN}/?redirect=${process.env.REACT_APP_BASE_URL}/${redirectTo}`;
    }
    navigate(redirectUrl);
  };

  return (
    <>
      <PublicCommonHeader />
      <section className="noticeboard-slider">
        <Carousel draggable fade>
          <div className="notice-board">
            <div className="tax-category-judge pt-24 pb-24 notice-board-redirect-section">
              <div className="container">
                <div className="d-flex align-center notice-board-container gap-64">
                  <div className="calendar">
                    <img src="/desktop-calendar.png" alt="" />
                  </div>
                  <div className="d-flex flex-vertical gap-16 content ">
                    <h2 className="semibold">Notice Board</h2>
                    <h4 className="medium text-medium">
                      Automatically grabs and organizes
                      <b className="text-dark"> Income Tax notices </b> in one
                      place, keeping track of when you need to respond and by
                      what date.
                    </h4>
                    <div className="relative">
                      <Button
                        onClick={() => handleNavigate(`/${ROUTES.DIRECT_TAX}`)}
                        type="primary"
                      >
                        Go To Income Tax
                      </Button>
                      <span className="vector">
                        <RoundArrow />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* kept for future use */}
          {/*  <div className="notice-board">
            <div className="tax-category-judge pt-24 pb-24 notice-board-redirect-section">
              <div className="container">
                <div className="d-flex align-center notice-board-container gap-64">
                  <div className="calendar">
                    <img src="/desktop-calendar.png" alt="" />
                  </div>
                  <div className="d-flex flex-vertical gap-16 content ">
                    <h2 className="semibold">Notice Board</h2>
                    <h4 className="medium text-medium">
                      Automatically grabs and organizes
                      <b className="text-dark"> GST notices </b> in one place,
                      keeping track of when you need to respond and by what
                      date.
                    </h4>
                    <div className="relative">
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#04724d',
                          },
                          components: {
                            Button: {
                              colorPrimary: '#04724d',
                              colorPrimaryHover: '#009d68',
                            },
                          },
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() =>
                            handleNavigate(`${ROUTES.INDIRECT_TAX}`)
                          }
                        >
                          Go To GST
                        </Button>
                      </ConfigProvider>
                      <span className="vector">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="150"
                          height="48"
                          viewBox="0 0 150 48"
                          fill="none"
                        >
                          <path
                            d="M6.64683 19.5329C4.74788 26.1092 2.38362 35.019 1.64032 41.7438M1.64032 41.7438C1.55441 42.5186 7.30036 41.4157 7.56094 41.3425C13.2787 39.7418 18.6114 37.4419 23.9961 34.9813M1.64032 41.7438C19.5509 9.14276 54.1764 -5.53488 78.9536 3.91732C81.4093 4.85413 84.0811 5.87634 86.6504 7.13192M86.6504 7.13192C90.7422 9.13146 94.5738 11.7229 96.8586 15.5037C98.967 18.9932 100.244 24.4271 98.1488 28.1427C94.2351 35.0836 83.8191 33.2959 80.7613 26.2511C78.074 20.0605 80.8946 10.5151 86.6504 7.13192ZM86.6504 7.13192C86.7719 7.06056 86.8946 6.99193 87.0186 6.92614C116.948 -8.95314 145.493 18.8326 148.465 46.3979"
                            stroke="#D966D3"
                            strokeWidth="2"
                            strokeMiterlimit="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Carousel>
      </section>

      <section className="notice-board-feature">
        <div className="container">
          <h2>Features of Notice Board</h2>
          <div className="feature-wrapper">
            <div className="feature-banner">
              <img src={FeatureBanner} />
            </div>
            <div className="feature-content">
              <Row gutter={16}>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M215.93,100.72A80,80,0,0,0,56,104L32.65,148.87a8,8,0,0,0,3.77,10.31L64,171.81V208a8,8,0,0,0,8,8h48a16,16,0,0,0,16,16h56l-8-64A79.86,79.86,0,0,0,215.93,100.72ZM112.13,74.08a16,16,0,1,1,13.79,13.79A16,16,0,0,1,112.13,74.08Zm34,77.79a16,16,0,1,1,13.79-13.79A16,16,0,0,1,146.08,151.87Z"
                          opacity="0.2"
                        ></path>
                        <path d="M192.5,171.47A88.34,88.34,0,0,0,224,101.93c-1-45.71-37.61-83.4-83.24-85.8A88,88,0,0,0,48,102L25.55,145.18c-.09.18-.18.36-.26.54a16,16,0,0,0,7.55,20.62l.25.11L56,176.94V208a16,16,0,0,0,16,16h48a8,8,0,0,0,0-16H72V171.81a8,8,0,0,0-4.67-7.28L40,152l23.07-44.34A7.9,7.9,0,0,0,64,104a72,72,0,0,1,56-70.21V49.38a24,24,0,1,0,16,0V32c1.3,0,2.6,0,3.9.1A72.26,72.26,0,0,1,203.84,80H184a8,8,0,0,0-6.15,2.88L152.34,113.5a24.06,24.06,0,1,0,12.28,10.25L187.75,96h19.79q.36,3.12.44,6.3a72.26,72.26,0,0,1-28.78,59.3,8,8,0,0,0-3.14,7.39l8,64a8,8,0,0,0,7.93,7,8.39,8.39,0,0,0,1-.06,8,8,0,0,0,6.95-8.93ZM128,80a8,8,0,1,1,8-8A8,8,0,0,1,128,80Zm16,64a8,8,0,1,1,8-8A8,8,0,0,1,144,144Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Automate The Retrieval Of Notices</h4>
                      <p>
                        Automate the retrieval of notices from Income Tax and
                        GST portal including attachments and responses.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M208,48V216a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8H96a39.83,39.83,0,0,0-8,24v8h80V64a39.83,39.83,0,0,0-8-24h40A8,8,0,0,1,208,48Z"
                          opacity="0.2"
                        ></path>
                        <path d="M168,152a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,152Zm-8-40H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm56-64V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V48A16,16,0,0,1,56,32H92.26a47.92,47.92,0,0,1,71.48,0H200A16,16,0,0,1,216,48ZM96,64h64a32,32,0,0,0-64,0ZM200,48H173.25A47.93,47.93,0,0,1,176,64v8a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V64a47.93,47.93,0,0,1,2.75-16H56V216H200Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Centralize All Notices</h4>
                      <p>
                        Centralize all notices in a unified repository,
                        simplifying access and enhancing visibility for tracking
                        and management purposes through intuitive dashboard.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M96,40l33.52,88H56Zm104,88H129.52L160,208Z"
                          opacity="0.2"
                        ></path>
                        <path d="M240,128a8,8,0,0,1-8,8H204.94l-37.78,75.58A8,8,0,0,1,160,216h-.4a8,8,0,0,1-7.08-5.14L95.35,60.76,63.28,131.31A8,8,0,0,1,56,136H24a8,8,0,0,1,0-16H50.85L88.72,36.69a8,8,0,0,1,14.76.46l57.51,151,31.85-63.71A8,8,0,0,1,200,120h32A8,8,0,0,1,240,128Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Comprehensive Tracking And Filtering</h4>
                      <p>
                        Provide comprehensive tracking and filtering of
                        deadlines, responses, and pending compliance actions to
                        avoid lapses in regulatory obligations.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M216,136a88,88,0,1,1-88-88A88,88,0,0,1,216,136Z"
                          opacity="0.2"
                        ></path>
                        <path d="M128,40a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,40Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,216ZM61.66,37.66l-32,32A8,8,0,0,1,18.34,58.34l32-32A8,8,0,0,1,61.66,37.66Zm176,32a8,8,0,0,1-11.32,0l-32-32a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,237.66,69.66ZM184,128a8,8,0,0,1,0,16H128a8,8,0,0,1-8-8V80a8,8,0,0,1,16,0v48Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Reminders For Upcoming Deadlines</h4>
                      <p>
                        Issue timely reminders for upcoming deadlines to
                        facilitate proactive management of notices and ensure
                        adherence to timelines.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M216,48V88H40V48a8,8,0,0,1,8-8H208A8,8,0,0,1,216,48Z"
                          opacity="0.2"
                        ></path>
                        <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-68-76a12,12,0,1,1-12-12A12,12,0,0,1,140,132Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,184,132ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,140,172Zm44,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Add Due Dates Into Outlook Or Google Calendar</h4>
                      <p>
                        Integrate essential dates, such as due dates, and
                        compliance deadlines, into Outlook or Google Calendar
                        for better planning even in offline mode.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="feature-card">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#004373"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M200,56H56A24,24,0,0,0,32,80V192a24,24,0,0,0,24,24H200a24,24,0,0,0,24-24V80A24,24,0,0,0,200,56ZM164,184H92a20,20,0,0,1,0-40h72a20,20,0,0,1,0,40Z"
                          opacity="0.2"
                        ></path>
                        <path d="M200,48H136V16a8,8,0,0,0-16,0V48H56A32,32,0,0,0,24,80V192a32,32,0,0,0,32,32H200a32,32,0,0,0,32-32V80A32,32,0,0,0,200,48Zm16,144a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V80A16,16,0,0,1,56,64H200a16,16,0,0,1,16,16ZM72,108a12,12,0,1,1,12,12A12,12,0,0,1,72,108Zm88,0a12,12,0,1,1,12,12A12,12,0,0,1,160,108Zm4,28H92a28,28,0,0,0,0,56h72a28,28,0,0,0,0-56Zm-24,16v24H116V152ZM80,164a12,12,0,0,1,12-12h8v24H92A12,12,0,0,1,80,164Zm84,12h-8V152h8a12,12,0,0,1,0,24Z"></path>
                      </svg>
                    </div>
                    <div>
                      <h4>Use Of Generating AI</h4>
                      <p>
                        Use of generating AI to give suggestive action on the
                        notices.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <PublicCommonFooter />
    </>
  );
}

export default Home;
