import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, Collapse, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  AdditionalNotice,
  Attachment,
  Reply,
} from '../../../__generated__/graphql';
import { DownArrow, FilePDF, PdfIcon, UpArrow } from '../../../assets/svg';
import {
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  EMPTY_STATE,
} from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import ProfileDetailCard from './components/ProfileDetailCard';
import { NOTICE_DETAILS } from './graphql/Queries';
import './notice.less';
import useRouter from '../../../hooks/useRouter';
import { COMPANY } from '../companies/graphql/Queries';

const { Text } = Typography;

const NoticesOrdersDetail = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get(COMMON_QUERY_PARAMS.NOTICE_ID);
  const { params } = useRouter();
  const { id: companyId } = params;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [attachments, setAttachment] = useState<Attachment[]>([]);

  const { data: { additionalNotice = {} } = {}, loading } = useQuery(
    NOTICE_DETAILS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      variables: { where: { id: id } },
    },
  );

  const [getCompany, { data: company }] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (companyId) {
      getCompany({
        variables: {
          where: { id: companyId },
        },
      });
    }
  }, [companyId]);

  const handleAttachment = (item: AdditionalNotice) => {
    if ((item?.attachments?.length ?? 0) > 0) {
      setOpenModal(true);
      setAttachment(item?.attachments as Attachment[]);
    }
  };

  const intimationsColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Reference no.',
      dataIndex: 'refId',
      key: 'refId',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Due Date to Reply',
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Section No.',
      dataIndex: 'sectionNo',
      key: 'sectionNo',
    },
    {
      title: 'Attachments',
      key: 'sectionNo',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  View All
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const noticesColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Reference no.',
      dataIndex: 'refId',
      key: 'refId',
    },
    {
      title: 'Issue Date',
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Due Date to Reply',
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    /* kept for future use */
    /* {
      title: 'Notice Issued by',
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (text: string) => '_check_',
    }, */
    {
      title: 'Personal Hearing',
      dataIndex: 'personalHearing',
      key: 'personalHearing',
    },
    {
      title: 'Section No.',
      dataIndex: 'sectionNo',
      key: 'sectionNo',
    },
    {
      title: 'Financial year',
      dataIndex: 'financialYear',
      key: 'financialYear',
    },
    /* kept for future use */
    /*  {
      title: 'Subject',
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (text: string) => '_check_',
    }, */
    {
      title: 'Attachments',
      key: 'sectionNo',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  View All
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const repliesColumns = [
    {
      title: 'Reply Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Reply Filed Against',
      dataIndex: 'repliedAgainstFiled',
      key: 'repliedAgainstFiled',
    },
    {
      title: 'Reply Date',
      dataIndex: 'replyDate',
      key: 'replyDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    /* kept for future use */
    /*   {
      title: 'Original Due Date',
      dataIndex: 'pan',
      key: 'pan',
      render: (text: string) => '_check_',
    },
    {
      title: 'Filed Up',
      dataIndex: 'pan',
      key: 'pan',
      render: (text: string) => '_check_',
    }, */
    {
      title: 'Option for personal hearing',
      dataIndex: 'personalHearing',
      key: 'personalHearing',
    },
    {
      title: 'Attachments',
      key: 'caseId',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  View All
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const ordersColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: '_id',
    },
    {
      title: 'Order No.',
      key: '_id',
      render: (_: string, item: AdditionalNotice) =>
        item?.section === 'ORDER' ? item?.refId : '-',
    },
    {
      title: 'Order Date',
      key: '_id',
      render: (_: string, item: AdditionalNotice) =>
        item?.section === 'ORDER'
          ? dayjs(item.issuanceDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Attachments',
      key: 'sectionNo',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  View All
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="container">
      <LoaderComponent spinning={loading}>
        <div className="mt-16 mb-16 d-flex justify-between">
          <MyBreadcrumb
            username={company?.company?.gstNumber ?? ''}
            id={(companyId || company?.company?._id) ?? ''}
          />
        </div>
        <ProfileDetailCard
          title="Notices & Orders"
          caseId={additionalNotice?.arn ?? ''}
          gstInNumber={additionalNotice?.gstIn ?? ''}
          applicationDate={
            additionalNotice?.arnDate
              ? dayjs(additionalNotice?.arnDate).format(defaultDateFormat)
              : '-'
          }
          status={additionalNotice?.status ?? ''}
        />
        <Card className="mb-24">
          <Collapse
            className="table-collapse"
            defaultActiveKey={['1']}
            items={[
              {
                key: '1',
                label: <Text className="title">Intimations</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={intimationsColumns as []}
                    dataSource={
                      additionalNotice?.INTIMATION as AdditionalNotice[]
                    }
                    locale={EMPTY_STATE}
                    scroll={{ x: 'max-content' }}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Notices</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={noticesColumns as []}
                    dataSource={additionalNotice?.NOTICE as AdditionalNotice[]}
                    locale={EMPTY_STATE}
                    scroll={{ x: 'max-content' }}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Replies</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={repliesColumns as []}
                    dataSource={additionalNotice?.REPLY as Reply[]}
                    locale={EMPTY_STATE}
                    scroll={{ x: 'max-content' }}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Orders</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={ordersColumns as []}
                    dataSource={additionalNotice?.ORDER as AdditionalNotice[]}
                    locale={EMPTY_STATE}
                    scroll={{ x: 'max-content' }}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
      </LoaderComponent>
      <CommonModal
        className="h-512"
        title="Attachments"
        open={openModal}
        footer={false}
        closable={true}
        onCancel={() => {
          setOpenModal(false);
          setAttachment([]);
        }}
        wrapClassName="attachment-modal"
      >
        <div className="d-flex gap-16 flex-vertical">
          {attachments?.map((file: Attachment, index: number) => (
            <div className="d-flex align-center" key={index}>
              <span className="mr-8 d-flex">
                <FilePDF />
              </span>
              <Link
                className="color-blue max-width-430"
                to={file?.url || ''}
                download
                target="_blank"
              >
                {file?.name}
              </Link>
            </div>
          ))}
        </div>
      </CommonModal>
    </div>
  );
};

export default NoticesOrdersDetail;
