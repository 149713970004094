import useRouter from '../../hooks/useRouter';
/* info
 This custom hook is use for get all query parameters from url and return object
*/
function useQueryValue() {
  const { location } = useRouter();
  const params = new URLSearchParams(location.search);
  /* any used due to type conflict */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const urlValueToObject: any = {};
  /* any used due to type conflict */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsEntries: any = params.entries();
  /* any used due to type conflict */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsEntries.forEach((item: any) => {
    urlValueToObject[item[0]] = item[1];
  });
  return urlValueToObject;
}

export default useQueryValue;
