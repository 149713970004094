import { Card, Divider } from 'antd';
import {
  DurationType,
  NoticeAndOrderStatus,
} from '../../__generated__/graphql';
import {
  INDIRECT_TAX,
  ROUTES,
  ZERO_PLACEHOLDER_TEXT,
} from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { StatisticData } from '../../types/common.type';
import StatisticCard from '../StatiscticCard';

const Overview = ({ statisticData }: { statisticData: StatisticData[] }) => {
  const { navigate } = useRouter();
  const navigateToListPage = (items: StatisticData) => {
    if (items?.route) {
      navigate(items?.route);
    } else {
      if (location.pathname.includes(INDIRECT_TAX)) {
        ['failedLogin', 'allPan'].includes(items?.key ?? '')
          ? navigate(`${ROUTES.COMPANY}?status=${items?.key}`)
          : navigate(
              `${ROUTES.NOTICE_ORDERS}?duration=${items?.key}${
                items?.key !== DurationType?.Last_24Hours &&
                items?.key !== DurationType?.All
                  ? `&status=${NoticeAndOrderStatus?.Open}`
                  : ''
              }`,
            );
      } else {
        ['failedLogin', 'allPan'].includes(items?.key ?? '')
          ? navigate(
              `/${ROUTES.DIRECT_TAX}/${ROUTES.CLIENT}?status=${items?.key}`,
            )
          : navigate(
              `/${ROUTES.DIRECT_TAX}/${ROUTES.TAX_LITIGATION}?duration=${items?.key}${
                items?.key !== DurationType?.Last_24Hours &&
                items?.key !== DurationType?.All
                  ? '&status=PENDING'
                  : ''
              }`,
            );
      }
    }
  };

  return (
    <Card className="mb-16">
      <div className="statistic-cards">
        {statisticData.map((items, i) => {
          return (
            <>
              <StatisticCard
                key={items?.label}
                icon={items?.icon}
                label={items?.label}
                value={items?.value || ZERO_PLACEHOLDER_TEXT}
                onClick={() => navigateToListPage(items as StatisticData)}
              />
              {i === 3 && statisticData.length >= 8 && <Divider />}
            </>
          );
        })}
      </div>
    </Card>
  );
};

export default Overview;
