import { Card, Typography } from 'antd';
import dayjs from 'dayjs';
import { Company } from '../../../../__generated__/graphql';
import { NA_PLACEHOLDER_TEXT } from '../../../../common/constants';
import { PeriodicityReturn, Proprietor } from '../graphql/clients.type';

const { Text } = Typography;

const CardList = ({ cards }: { cards: Company }) => {
  const currentYear = dayjs().year();

  return (
    <Card className="full-width">
      <div className="d-flex flex-vertical gap-16">
        <Text className="heading">Other Details</Text>
        <div className="d-flex gap-16">
          <Card key={cards?._id} className="width-percent-25">
            <div className="d-flex flex-vertical gap-16">
              <span>
                <Text className="title fs-16">Business Owners</Text>
              </span>
              <span>
                {Array.isArray(cards.proprietor) ? (
                  cards.proprietor.map((owner: Proprietor, index: number) => (
                    <div key={index} className="d-flex flex-vertical">
                      <Text className="value fs-16">
                        {owner?.name || NA_PLACEHOLDER_TEXT}
                      </Text>
                    </div>
                  ))
                ) : (
                  <Text className="value fs-16">No data available</Text>
                )}
              </span>
            </div>
          </Card>
          <Card className="width-percent-25">
            <div className="d-flex flex-vertical gap-16">
              <Text className="title fs-16">HSN / SAC</Text>
              <div className="value fs-16 d-flex flex-vertical">
                {cards?.hsn?.map((hsnNo, index) => (
                  <Text key={index} className="fs-16">
                    {hsnNo || NA_PLACEHOLDER_TEXT}
                  </Text>
                ))}
              </div>
            </div>
          </Card>
          <Card className="width-percent-25">
            <div className="d-flex flex-vertical gap-16">
              <Text className="title fs-16">Return Periodicity</Text>
              <div className="d-flex justify-between">
                {[0, 6].map((startIndex, sectionIndex) => {
                  const periodicityData = cards?.periodicityReturn?.slice(
                    startIndex,
                    startIndex + 6,
                  );
                  const hasData = periodicityData?.length > 0;

                  return (
                    <span key={sectionIndex} className="d-flex flex-vertical">
                      <Text className="fs-16">
                        {hasData && `${currentYear} - ${currentYear + 1}`}
                      </Text>
                      {hasData
                        ? periodicityData.map(
                            (
                              periodicityReturn: PeriodicityReturn,
                              index: number,
                            ) => (
                              <Text key={index} className="value fs-16 d-flex">
                                {periodicityReturn.quarter}{' '}
                                {periodicityReturn.period}
                              </Text>
                            ),
                          )
                        : sectionIndex === 0 && (
                            <Text className="value fs-16">
                              No data available
                            </Text>
                          )}
                    </span>
                  );
                })}
              </div>
            </div>
          </Card>
          <Card className="width-percent-25">
            <div className="d-flex flex-vertical gap-16">
              <span>
                <Text className="title fs-16">Business Activities</Text>
              </span>
              <span>
                {cards?.natureOfBusinessActivity?.map(
                  (activity: string, index: number) => (
                    <Text key={index} className="value fs-16 d-flex">
                      {activity}
                    </Text>
                  ),
                )}
              </span>
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
};

export default CardList;
