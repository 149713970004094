import { Outlet } from 'react-router-dom';
import { Cookie } from './common/utils';
import { ROUTES } from './common/constants';
import useRouter from './hooks/useRouter';

const PrivateRoute = () => {
  const idToken = Cookie.get(Cookie.keys.TOKEN);
  const { location } = useRouter();
  const redirectTo = location?.pathname?.includes(ROUTES?.INDIRECT_TAX)
    ? ROUTES?.INDIRECT_TAX
    : ROUTES?.DIRECT_TAX;
  const redirectUrl = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.LOGIN}/?redirect=${process.env.REACT_APP_BASE_URL}/${redirectTo}`;
  if (!idToken) {
    window.open(redirectUrl, '_self');
  }
  return <Outlet />;
};
export default PrivateRoute;
