import type { BadgeProps, CalendarProps } from 'antd';
import { Badge, Calendar, Row, Select, theme } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import dayLocaleData from 'dayjs/plugin/localeData';
import React, { useState } from 'react';
import {
  CompanyCalenderCountResponse,
  EProceedingCalenderCountResponse,
} from '../__generated__/graphql';
import { CaretLeftRegular, CaretRight } from '../assets/svg';
import { defaultDateFormat } from '../common/constants';
import { EProceedingCalendarData } from '../types/common.type';

dayjs.extend(dayLocaleData);

const CommonCalendar = ({
  calendarCountList,
  getSelectedNotice,
  getSelectedRange,
}: {
  calendarCountList:
    | EProceedingCalenderCountResponse
    | CompanyCalenderCountResponse;
  getSelectedNotice: (notice: EProceedingCalendarData) => void;
  getSelectedRange: (dateString: [string, string]) => void;
}) => {
  const { token } = theme.useToken();
  const currentDate = dayjs().format(defaultDateFormat);
  const [selectedDate, setSelectedData] = useState(currentDate);

  const wrapperStyle: React.CSSProperties = {
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
    marginTop: '8px',
  };

  const dateCellRender = (value: Dayjs) => {
    if (!calendarCountList?.data) return;
    const item = calendarCountList?.data?.find(
      (item: EProceedingCalendarData) =>
        item.date === value.format(defaultDateFormat),
    );
    /* kept for future use */
    // const issuedOn = item?.issuedOn || 0;
    const responseDueCount = item?.responseDueCount ?? 0;
    let backgroundColor = '';

    if (responseDueCount > 0) {
      backgroundColor = '#E64E2C';
    }

    /* kept for future use */
    /* if (issuedOn > 0 && responseDueCount > 0) {
      backgroundColor = '#FA8C16';
    } else if (issuedOn > 0) {
      backgroundColor = '#006FC0';
    } else if (responseDueCount > 0) {
      backgroundColor = '#E64E2C';
    } */

    const style = {
      backgroundColor,
      color: backgroundColor ? 'white' : '',
      padding: '3px',
      borderRadius: '4px',
    };

    return (
      <Badge
        status={'error' as BadgeProps['status']}
        text={
          <span
            onClick={() => {
              //added below condition for now,in future while we use the above commented dynamic code of color then we can remove this condition.
              if (item?.responseDueCount) {
                getSelectedNotice(item as EProceedingCalendarData);
                setSelectedData(value?.format(defaultDateFormat));
              }
            }}
            style={backgroundColor ? { color: 'white' } : undefined}
          >
            {value.format('DD')}
          </span>
        }
        style={style}
        className={`${selectedDate === value?.format(defaultDateFormat) ? 'selected-date' : ''}`}
      />
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  return (
    <div style={wrapperStyle} className="calendar-container">
      <Calendar
        fullscreen={false}
        fullCellRender={cellRender}
        headerRender={({ value, onChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          let current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }
          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>,
            );
          }

          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>,
            );
          }
          return (
            <div style={{ padding: 8 }}>
              <Row gutter={8} className="d-flex justify-between">
                <span
                  className="pointer"
                  onClick={() => {
                    const now = value.clone().month(month - 1);
                    onChange(now);
                    getSelectedRange([
                      now.startOf('month').format('YYYY-MM-DD'),
                      now.endOf('month').format('YYYY-MM-DD'),
                    ]);
                  }}
                >
                  <CaretLeftRegular />
                </span>
                <p className="m-0">
                  {months[month]} {year}
                </p>
                <span
                  className="pointer"
                  onClick={() => {
                    const now = value.clone().month(month + 1);
                    onChange(now);
                    getSelectedRange([
                      now.startOf('month').format('YYYY-MM-DD'),
                      now.endOf('month').format('YYYY-MM-DD'),
                    ]);
                  }}
                >
                  <CaretRight />
                </span>
              </Row>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CommonCalendar;
