import { gql } from '../../../../__generated__';

export const ITF_LIST = gql(`query ItfLists($filter: IncomeTaxFormsFilter) {
  itfList(filter: $filter) {
    data {
      _id
      acknowledgmentNo
      assessmentYear
      financialYear
      formNo
      filingCount
      filedBy
      filingType
      filingDate
      status
      name
      formSubmittedDate
      createdAt
      updatedAt
      attachments {
        name
        url
      }
      forms {
        name
        url
      }
      receipts {
        name
        url
      }
      assessee {
        _id
        username
        name
      }
    }
    count
  }
}`);

export const ITF = gql(`query Itf($where: ITFUniqueInput!) {
  itf(where: $where) {
    _id
    assessee {
      _id
      username
      name
    }
    acknowledgmentNo
    assessmentYear
    financialYear
    formNo
    filingCount
    filedBy
    filingType
    filingDate
    status
    name
    attachments {
      name
      url
    }
    forms {
      name
      url
    }
    receipts {
      name
      url
    }
    formSubmittedDate
    createdAt
    updatedAt

  }
}`);

export const ASSESSEES_LIST =
  gql(`query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {
  assessees(filter: $filter, sort: $sort) {
    data {
      username
      _id
      name
    }
    count
  }
}`);

export const FILING_TYPES = gql(`query Query {
  itfFilingTypes
}`);
